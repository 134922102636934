import { EventEmitter, ListenerFn } from "eventemitter3";

import { Barcode } from "./barcode";
import { BrowserHelper } from "./browserHelper";
import { EngineLoader } from "./engineLoader";
import { ScanSettings } from "./scanSettings";
import { EngineSentMessageData, EngineWorker, engineWorkerBlob } from "./workers/engineWorker";

type EventName = "blurryTablesUpdate";

class BlurryRecognitionPreloaderEventEmitter extends EventEmitter<EventName> {}

export class BlurryRecognitionPreloader {
  private static readonly writableDataPath: string = "/scandit_sync_folder_preload";
  private static readonly fsObjectStoreName: string = "FILE_DATA";
  // From AndroidLowEnd
  private static readonly blurryTableFiles: string[] = [
    "/1a3f08f42d1332344e3cebb5c53d9837.scandit", // code32, code39
    "/9590b4b7b91d4a5ed250c07e3e6d817c.scandit", // code32, code39
    "/d5739c566e6804f3870e552f90e3afd6.scandit", // code32, code39
    "/131e51bb75340269aa65fd0e79092b88.scandit", // code93
    "/6e1a9119f3e7960affc7ec57d5444ee7.scandit", // code93
    "/d6fc3b403665c15391a34f142ee5a59a.scandit", // code93
    "/01c4e5de021dbfcf8d2379ce1cf92e73.scandit", // code128
    "/102ada10d9d30c97397b492d7d0f1723.scandit", // code128
    "/fbe00505a2fc101192022da06b10f6e4.scandit", // code128
    "/b7ee4f18825bd3369ad7afbca72f4a58.scandit", // ean13, ean8, upca, upce
    "/d6401bde0bf283d9e25b41ce39eb37f5.scandit", // ean13, ean8, upca, upce
    "/f40acf1ec5d358e51e0339ace0e52513.scandit", // ean13, ean8, upca, upce
    "/76ca9155b19b81b4ea4a209c9c2154a4.scandit", // itf
    "/9da3d4277f729835f5a1b00f8222de44.scandit", // itf
    "/bdbc0442a6bd202f813411397db5e7d7.scandit", // itf
    "/3c977e4745212da13b988db64d793b01.scandit", // msi-plessey
    "/b04cd3b79ca8a4972422d95b71c4a33f.scandit", // msi-plessey
    "/deaa2ce67c6953bdeef1fb9bcdd91d3f.scandit", // msi-plessey
  ].map((path) => {
    return `${BlurryRecognitionPreloader.writableDataPath}${path}`;
  });
  // Roughly ordered by priority
  private static readonly availableBlurryRecognitionSymbologies: Set<Barcode.Symbology> = new Set([
    Barcode.Symbology.EAN13, // Shared with EAN8, UPCA, UPCE
    Barcode.Symbology.EAN8, // Shared with EAN13, UPCA, UPCE
    Barcode.Symbology.CODE32, // Shared with CODE39
    Barcode.Symbology.CODE39, // Shared with CODE32
    Barcode.Symbology.CODE128,
    Barcode.Symbology.CODE93,
    Barcode.Symbology.INTERLEAVED_2_OF_5,
    Barcode.Symbology.MSI_PLESSEY,
    Barcode.Symbology.UPCA, // Shared with EAN8, EAN13, UPCE
    Barcode.Symbology.UPCE, // Shared with EAN8, EAN13, UPCA
  ]);

  private readonly eventEmitter: BlurryRecognitionPreloaderEventEmitter = new EventEmitter();
  private readonly preload: boolean;

  private queuedBlurryRecognitionSymbologies: Barcode.Symbology[] = Array.from(
    BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.values()
  );
  private readyBlurryRecognitionSymbologies: Set<Barcode.Symbology> = new Set();
  private engineWorker: EngineWorker;

  private constructor(preload: boolean) {
    this.preload = preload;
  }

  public static async create(preload: boolean): Promise<BlurryRecognitionPreloader> {
    if (preload) {
      // Edge <= 18 doesn't support IndexedDB in blob Web Workers so data wouldn't be persisted,
      // hence it would be useless to preload blurry recognition as data couldn't be saved.
      // Verify support for IndexedDB in blob Web Workers.
      const browserName: string | undefined = BrowserHelper.userAgentInfo.getBrowser().name;
      if (browserName != null && browserName.includes("Edge")) {
        const worker: Worker = new Worker(
          URL.createObjectURL(
            new Blob([`(${BlurryRecognitionPreloader.workerIndexedDBSupportTestFunction.toString()})()`], {
              type: "text/javascript",
            })
          )
        );

        return new Promise((resolve) => {
          worker.onmessage = (message) => {
            worker.terminate();
            resolve(new BlurryRecognitionPreloader(message.data));
          };
        });
      }
    }

    return new BlurryRecognitionPreloader(preload);
  }

  // istanbul ignore next
  private static workerIndexedDBSupportTestFunction(): void {
    try {
      indexedDB.deleteDatabase("scandit_indexeddb_support_test");
      // @ts-ignore
      postMessage(true);
    } catch (error) {
      // @ts-ignore
      postMessage(false);
    }
  }

  public async prepareBlurryTables(): Promise<void> {
    let alreadyAvailable: boolean = true;
    if (this.preload) {
      try {
        alreadyAvailable = await this.checkBlurryTablesAlreadyAvailable();
      } catch (error) {
        // istanbul ignore next
        console.error(error);
      }
    }
    if (alreadyAvailable) {
      this.queuedBlurryRecognitionSymbologies = [];
      this.readyBlurryRecognitionSymbologies = new Set(
        BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies
      );
      this.eventEmitter.emit("blurryTablesUpdate", new Set(this.readyBlurryRecognitionSymbologies));
    } else {
      this.engineWorker = new Worker(URL.createObjectURL(engineWorkerBlob));
      this.engineWorker.onmessage = this.engineWorkerOnMessage.bind(this);
      EngineLoader.load(this.engineWorker, true, true);
    }
  }

  public on(eventName: EventName, listener: ListenerFn): void {
    // istanbul ignore else
    if (eventName === "blurryTablesUpdate") {
      if (
        this.readyBlurryRecognitionSymbologies.size ===
        BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.size
      ) {
        listener(this.readyBlurryRecognitionSymbologies);
      } else {
        this.eventEmitter.on(eventName, listener);
      }
    }
  }

  public updateBlurryRecognitionPriority(scanSettings: ScanSettings): void {
    const newQueuedBlurryRecognitionSymbologies: Barcode.Symbology[] = this.queuedBlurryRecognitionSymbologies.slice();
    this.getEnabledSymbologies(scanSettings).forEach((symbology) => {
      const symbologyQueuePosition: number = newQueuedBlurryRecognitionSymbologies.indexOf(symbology);
      if (symbologyQueuePosition !== -1) {
        newQueuedBlurryRecognitionSymbologies.unshift(
          newQueuedBlurryRecognitionSymbologies.splice(symbologyQueuePosition, 1)[0]
        );
      }
    });
    this.queuedBlurryRecognitionSymbologies = newQueuedBlurryRecognitionSymbologies;
  }

  public isBlurryRecognitionAvailable(scanSettings: ScanSettings): boolean {
    const enabledBlurryRecognitionSymbologies: Barcode.Symbology[] = this.getEnabledSymbologies(scanSettings);

    return enabledBlurryRecognitionSymbologies.every((symbology) => {
      return this.readyBlurryRecognitionSymbologies.has(symbology);
    });
  }

  public getEnabledSymbologies(scanSettings: ScanSettings): Barcode.Symbology[] {
    return Array.from(BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.values()).filter((symbology) => {
      return scanSettings.isSymbologyEnabled(symbology);
    });
  }

  private createNextBlurryTableSymbology(): void {
    let symbology: Barcode.Symbology | undefined;
    do {
      symbology = this.queuedBlurryRecognitionSymbologies.shift();
    } while (symbology != null && this.readyBlurryRecognitionSymbologies.has(symbology));
    // istanbul ignore else
    if (symbology != null) {
      this.engineWorker.postMessage({
        type: "create-blurry-table",
        symbology,
      });
    }
  }

  private checkBlurryTablesAlreadyAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      const openDbRequest: IDBOpenDBRequest = indexedDB.open(BlurryRecognitionPreloader.writableDataPath);
      function handleErrorOrNew(this: IDBOpenDBRequest | IDBTransaction | IDBRequest | { error: Error }): void {
        openDbRequest?.result?.close();
        // this.error
        resolve(false);
      }

      openDbRequest.onupgradeneeded = () => {
        try {
          openDbRequest.result.createObjectStore(BlurryRecognitionPreloader.fsObjectStoreName);
        } catch (error) {
          // Ignored
        }
      };
      openDbRequest.onsuccess = () => {
        try {
          const transaction: IDBTransaction = openDbRequest.result.transaction(
            BlurryRecognitionPreloader.fsObjectStoreName,
            "readonly"
          );
          transaction.onerror = handleErrorOrNew;
          const storeKeysRequest: IDBRequest<IDBValidKey[]> = transaction
            .objectStore(BlurryRecognitionPreloader.fsObjectStoreName)
            .getAllKeys();
          storeKeysRequest.onsuccess = () => {
            openDbRequest.result.close();
            if (
              BlurryRecognitionPreloader.blurryTableFiles.every((file) => {
                return storeKeysRequest.result.indexOf(file) !== -1;
              })
            ) {
              return resolve(true);
            } else {
              return resolve(false);
            }
          };
          storeKeysRequest.onerror = handleErrorOrNew;
        } catch (error) {
          handleErrorOrNew.call({ error });
        }
      };
      openDbRequest.onblocked = openDbRequest.onerror = handleErrorOrNew;
    });
  }

  private engineWorkerOnMessage(ev: MessageEvent): void {
    const data: EngineSentMessageData = ev.data;

    // istanbul ignore else
    if (data[1] != null) {
      switch (data[0]) {
        case "context-created":
          this.createNextBlurryTableSymbology();
          break;
        case "create-blurry-table-result":
          this.readyBlurryRecognitionSymbologies.add(data[1]);
          if (
            [Barcode.Symbology.EAN8, Barcode.Symbology.EAN13, Barcode.Symbology.UPCA, Barcode.Symbology.UPCE].includes(
              data[1]
            )
          ) {
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.EAN13);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.EAN8);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.UPCA);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.UPCE);
          } else if ([Barcode.Symbology.CODE32, Barcode.Symbology.CODE39].includes(data[1])) {
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.CODE32);
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.CODE39);
          }
          this.eventEmitter.emit("blurryTablesUpdate", new Set(this.readyBlurryRecognitionSymbologies));
          if (
            this.readyBlurryRecognitionSymbologies.size ===
            BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.size
          ) {
            // Avoid data not being persisted if IndexedDB operations in WebWorker are slow
            setTimeout(() => {
              this.engineWorker.terminate();
            }, 250);
          } else {
            this.createNextBlurryTableSymbology();
          }
          break;
        // istanbul ignore next
        default:
          break;
      }
    }
  }
}
