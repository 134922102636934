import { Barcode, BarcodeWASMResult } from "../barcode";
import { Camera } from "../camera";
import { ImageSettings } from "../imageSettings";
import { Parser } from "../parser";

// WARNING
// ==========
// The "engine" function is extracted and executed in isolation as a WebWorker in the browser.
// We currently cannot use too advanced language features here as the code will not get transformed/polyfilled correctly
// by Rollup and Babel as it might refer to other externally defined variables/functions.
// This means we also cannot import and use variables from the rest of the project.
// The used language features should be compatible with (supported by) the browsers mentioned in the documentation.
// See rollup.config.js and .browserslistrc.worker for more details.
// TODO: This should be fixed...

// tslint:disable:no-any

declare const self: any;
declare const importScripts: (...urls: string[]) => Promise<void> | undefined; // Promise is used only during testing
declare const postMessage: (message: any, transfer?: any[]) => void;
// Defined here as we cannot use too recent typescript type definitions
declare namespace WebAssembly {
  interface Instance {
    readonly exports: any;
  }

  interface WebAssemblyInstantiatedSource {
    instance: Instance;
    // tslint:disable-next-line:no-reserved-keywords
    module: {};
  }
}

// tslint:enable:no-any

interface Module extends EmscriptenModule {
  callMain(): void;
  _create_context(
    licenseKeyPointer: number,
    writableDataPathPointer: number,
    delayedRegistration: boolean,
    debug: boolean
  ): void;
  _scanner_settings_new_from_json(
    jsonSettingsPointer: number,
    blurryRecognitionEnabled: boolean,
    matrixScanEnabled: boolean,
    highQualitySingleFrameMode: boolean,
    gpuEnabled: boolean
  ): number;
  _scanner_image_settings_new(width: number, height: number, channels: number): void;
  _scanner_session_clear(): void;
  _can_hide_logo(): number;
  _scanner_scan(imageDataPointer: number): number;
  _parser_parse_string(
    parserType: number,
    stringDataPointer: number,
    stringDataLength: number,
    optionsPointer: number
  ): number;
  _create_blurry_table(symbologyPointer: number): void;
  _report_camera_properties(frontFacingDirection: boolean, adjustsFocus: boolean): void;
}

declare let Module: Module;

declare type ScanWorkUnit = {
  requestId: number;
  data: Uint8Array;
  highQualitySingleFrameMode: boolean;
};

declare type ParseWorkUnit = {
  requestId: number;
  dataFormat: Parser.DataFormat;
  data: string | Uint8Array;
  options: string;
};

// tslint:disable:no-reserved-keywords
/**
 * @hidden
 */
export declare type EngineReceivedMessageData =
  | {
      type: "load-library";
      deviceId: string;
      libraryLocation: string;
      path: string;
      preload: boolean;
      delayedRegistration: boolean;
      licenseKey?: string;
      deviceModelName?: string;
    }
  | {
      type: "scan-settings";
      settings: string;
      blurryRecognitionAvailable: boolean;
      blurryRecognitionRequiresUpdate: boolean;
    }
  | { type: "image-settings"; imageSettings: ImageSettings }
  | { type: "scan-image"; requestId: number; data: Uint8Array; highQualitySingleFrameMode: boolean }
  | { type: "parse"; requestId: number; dataFormat: Parser.DataFormat; data: string | Uint8Array; options: string }
  | { type: "clear-session" }
  | { type: "create-blurry-table"; symbology: Barcode.Symbology }
  | { type: "camera-properties"; cameraType: Camera.Type; autofocus: boolean }
  | { type: "reset" };
// tslint:enable:no-reserved-keywords
/**
 * @hidden
 */
export declare type EngineSentMessageData =
  | ["library-loaded"]
  | ["context-created", object]
  | ["work-result", { requestId: number; result: { scanResult: BarcodeWASMResult[] } }, Uint8Array]
  | ["work-error", { requestId: number; error: { errorCode: number; errorMessage: string } }, Uint8Array]
  | ["parse-result", { requestId: number; result: string }]
  | ["parse-error", { requestId: number; error: { errorCode: number; errorMessage: string } }]
  | ["create-blurry-table-result", Barcode.Symbology];

/**
 * @hidden
 */
export interface EngineWorker extends Worker {
  onmessage: ((this: Worker, ev: MessageEvent & { data: EngineSentMessageData }) => void) | null;
  postMessage(message: EngineReceivedMessageData, transfer: Transferable[]): void;
  postMessage(
    message: EngineReceivedMessageData,
    options?: {
      // tslint:disable-next-line: no-any
      transfer?: any[];
    } // Use custom object instead of PostMessageOptions to support TypeScript < 3.5
  ): void;
}

/**
 * @hidden
 */
export declare type Engine = {
  loadLibrary(
    deviceId: string,
    libraryLocation: string,
    locationPath: string,
    preload: boolean,
    delayedRegistration: boolean,
    licenseKey?: string,
    deviceModelName?: string
  ): Promise<void>;
  setScanSettings(
    newScanSettings: string,
    blurryRecognitionAvailable: boolean,
    blurryRecognitionRequiresUpdate: boolean
  ): void;
  setImageSettings(imageSettings: ImageSettings): void;
  workOnScanQueue(): void;
  workOnParseQueue(): void;
  addScanWorkUnit(scanWorkUnit: ScanWorkUnit): void;
  addParseWorkUnit(parseWorkUnit: ParseWorkUnit): void;
  clearSession(): void;
  createBlurryTable(symbology: Barcode.Symbology): void;
  setCameraProperties(cameraType: Camera.Type, autofocus: boolean): void;
  reset(): void;
};

/**
 * @hidden
 *
 * @returns Engine
 */
// tslint:disable-next-line:max-func-body-length
export function engine(): Engine {
  const writableDataPathPreload: string = "/scandit_sync_folder_preload";
  const writableDataPathStandard: string = "/scandit_sync_folder";
  const scanQueue: ScanWorkUnit[] = [];
  const parseQueue: ParseWorkUnit[] = [];
  const gpuAccelerationAvailable: boolean = typeof self.OffscreenCanvas === "function";

  let originalFSSyncfs: typeof FS.syncfs | undefined;
  let imageBufferPointer: number | undefined;
  let imageBufferSize: number | undefined;
  let preloading: boolean;
  let writableDataPath: string;
  let delayedRegistration: boolean | undefined;
  let licenseKey: string | undefined;
  let scanSettings: string | undefined;
  let imageSettings: ImageSettings | undefined;
  let cameraProperties: { cameraType: Camera.Type; autofocus: boolean } | undefined;
  let blurryRecognitionAvailable: boolean = false;
  let workSubmitted: boolean = false;
  let loadingInProgress: boolean = false;
  let fileSystemSynced: boolean = false;
  let runtimeLoaded: boolean = false;
  let wasmReady: boolean = false;
  let scannerSettingsReady: boolean = false;
  let scannerImageSettingsReady: boolean = false;
  let contextAvailable: boolean = false;
  let fsSyncPromise: Promise<void> = Promise.resolve();
  let fsSyncInProgress: boolean = false;
  let fsSyncScheduled: boolean = false;

  // Public

  // Promise is used only during testing
  function loadLibrary(
    deviceId: string,
    libraryLocation: string,
    locationPath: string,
    preload: boolean,
    newDelayedRegistration: boolean,
    newLicenseKey?: string,
    deviceModelName?: string
  ): Promise<void> {
    function reportLoadSuccess(): void {
      postMessage(["library-loaded"]);
      createContext(newDelayedRegistration, newLicenseKey);
    }

    function start(): void {
      if (!wasmReady && fileSystemSynced && runtimeLoaded) {
        loadingInProgress = false;
        Module.callMain();
        wasmReady = true;
        reportLoadSuccess();
        if (!newDelayedRegistration) {
          workOnScanQueue();
          workOnParseQueue();
        }
      }
    }

    if (loadingInProgress) {
      return Promise.resolve();
    }

    if (wasmReady) {
      reportLoadSuccess();

      return Promise.resolve();
    }

    loadingInProgress = true;

    const { jsURI, wasmURI } = getLibraryLocationURIs(libraryLocation);
    preloading = preload;
    writableDataPath = preload ? writableDataPathPreload : writableDataPathStandard;
    self.window = self.document = self; // Fix some Emscripten quirks
    self.path = locationPath; // Used by the Scandit SDK Engine library
    self.deviceModelName = deviceModelName; // Used by the Scandit SDK Engine library
    Module = <Module>(<unknown>{
      arguments: [deviceId],
      canvas: gpuAccelerationAvailable ? new self.OffscreenCanvas(32, 32) : /* istanbul ignore next */ undefined,
      instantiateWasm: (importObject: object, successCallback: (instance: WebAssembly.Instance) => void) => {
        // wasmJSVersion is globally defined inside scandit-engine-sdk.min.js
        const wasmJSVersion: string = self.wasmJSVersion ?? "undefined";
        // istanbul ignore if
        if (wasmJSVersion !== "%VERSION%") {
          console.error(
            `The Scandit SDK Engine library JS file found at ${jsURI} seems invalid: ` +
              `expected version doesn't match (received: ${wasmJSVersion}, expected: ${"%VERSION%"}). ` +
              `Please ensure the correct Scandit SDK Engine file (with correct version) is retrieved.`
          );
        }

        if (typeof self.WebAssembly.instantiateStreaming === "function") {
          instantiateWebAssemblyStreaming(importObject, wasmURI, successCallback);
        } else {
          instantiateWebAssembly(importObject, wasmURI, successCallback);
        }

        return {};
      },
      noInitialRun: true,
      preRun: [
        () => {
          return setupFS()
            .catch((error) => {
              console.debug("No IndexedDB support, some data will not be persisted:", error);
            })
            .then(() => {
              fileSystemSynced = true;
              start();
            });
        },
      ],
      onRuntimeInitialized: () => {
        runtimeLoaded = true;
        start();
      },
    });

    function tryImportScripts(): Promise<void> {
      try {
        return importScripts(jsURI) ?? Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return retryWithExponentialBackoff(tryImportScripts, 250, 4000, (error) => {
      console.warn(error);
      console.warn(`Couldn't retrieve Scandit SDK Engine library at ${jsURI}, retrying...`);
    }).catch((error) => {
      console.error(error);
      console.error(
        `Couldn't retrieve Scandit SDK Engine library at ${jsURI}, did you configure the path for it correctly?`
      );

      return Promise.resolve(error); // Promise is used only during testing
    });
  }

  // tslint:disable-next-line: bool-param-default
  function createContext(newDelayedRegistration?: boolean, newLicenseKey?: string): void {
    function completeCreateContext(): void {
      postMessage([
        "context-created",
        {
          hiddenScanditLogoAllowed: Module._can_hide_logo() === 1,
        },
      ]);
    }

    if (contextAvailable) {
      return completeCreateContext();
    }

    if (newDelayedRegistration != null) {
      delayedRegistration = newDelayedRegistration;
    }
    if (newLicenseKey != null) {
      licenseKey = newLicenseKey;
    }
    if (!wasmReady || delayedRegistration == null || (!workSubmitted && !delayedRegistration) || licenseKey == null) {
      return;
    }

    const licenseKeyLength: number = lengthBytesUTF8(licenseKey) + 1;
    const licenseKeyPointer: number = Module._malloc(licenseKeyLength);
    stringToUTF8(licenseKey, licenseKeyPointer, licenseKeyLength);
    const writableDataPathLength: number = lengthBytesUTF8(writableDataPath) + 1;
    const writableDataPathPointer: number = Module._malloc(writableDataPathLength);
    stringToUTF8(writableDataPath, writableDataPathPointer, writableDataPathLength);
    Module._create_context(licenseKeyPointer, writableDataPathPointer, delayedRegistration, false);
    Module._free(licenseKeyPointer);
    Module._free(writableDataPathPointer);

    contextAvailable = true;

    reportCameraProperties();
    completeCreateContext();
  }

  function setScanSettings(
    newScanSettings: string,
    newBlurryRecognitionAvailable: boolean,
    blurryRecognitionRequiresUpdate: boolean
  ): void {
    function completeSetScanSettings(): void {
      scanSettings = newScanSettings;
      blurryRecognitionAvailable = newBlurryRecognitionAvailable;
      applyScanSettings();
      workOnScanQueue();
    }

    scanSettings = undefined;
    scannerSettingsReady = false;

    if (newBlurryRecognitionAvailable && blurryRecognitionRequiresUpdate) {
      syncFS(true, false, true).then(completeSetScanSettings).catch(completeSetScanSettings);
    } else {
      completeSetScanSettings();
    }
  }

  function setImageSettings(newImageSettings: ImageSettings): void {
    imageSettings = newImageSettings;
    applyImageSettings();
    workOnScanQueue();
  }

  function augmentErrorInformation(error: { errorCode: number; errorMessage: string }): void {
    if (error.errorCode === 260) {
      let hostname: string;
      // istanbul ignore if
      if (location.href?.indexOf("blob:null/") === 0) {
        hostname = "localhost";
      } else {
        hostname = new URL(
          location.pathname != null && location.pathname !== "" && !location.pathname.startsWith("/")
            ? /* istanbul ignore next */ location.pathname
            : location.origin
        ).hostname;
      }
      // istanbul ignore next
      if (hostname[0].startsWith("[") && hostname.endsWith("]")) {
        hostname = hostname.slice(1, -1);
      }
      error.errorMessage = error.errorMessage.replace("domain name", `domain name (${hostname})`);
    }

    // License Key related error codes from 6 to 25 and 260
    if (
      [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 260].includes(error.errorCode) &&
      licenseKey != null &&
      licenseKey.length > 0
    ) {
      error.errorMessage += ` License Key: ${licenseKey.slice(0, 15)}...`;
    }
  }

  function processScanWorkUnit(scanWorkUnit: ScanWorkUnit): void {
    if (scanWorkUnit.highQualitySingleFrameMode) {
      applyScanSettings(true);
    }
    const resultData: string = scanImage(scanWorkUnit.data);
    if (scanWorkUnit.highQualitySingleFrameMode) {
      applyScanSettings(false);
    }
    const result: {
      scanResult?: BarcodeWASMResult[];
      error?: { errorCode: number; errorMessage: string };
    } = JSON.parse(resultData);
    if (result.error != null) {
      augmentErrorInformation(result.error);
      postMessage(
        [
          "work-error",
          {
            requestId: scanWorkUnit.requestId,
            error: result.error,
          },
          scanWorkUnit.data,
        ],
        [scanWorkUnit.data.buffer]
      );
    } else {
      // istanbul ignore else
      if (result.scanResult != null) {
        postMessage(
          [
            "work-result",
            {
              requestId: scanWorkUnit.requestId,
              result,
            },
            scanWorkUnit.data,
          ],
          [scanWorkUnit.data.buffer]
        );
      }
    }
  }

  function workOnScanQueue(): void {
    if (!wasmReady || scanQueue.length === 0) {
      return;
    }

    // Initialization for first submitted work unit
    if (!contextAvailable) {
      createContext();
    }
    if (!scannerSettingsReady) {
      applyScanSettings();
    }
    if (!scannerImageSettingsReady) {
      applyImageSettings();
    }

    if (!contextAvailable || !scannerSettingsReady || !scannerImageSettingsReady) {
      return;
    }

    while (scanQueue.length !== 0) {
      if (scanQueue[0].highQualitySingleFrameMode && !blurryRecognitionAvailable) {
        break;
      }
      processScanWorkUnit(<ScanWorkUnit>scanQueue.shift());
    }
  }

  function processParseWorkUnit(parseWorkUnit: ParseWorkUnit): void {
    const resultData: string = parse(parseWorkUnit.dataFormat, parseWorkUnit.data, parseWorkUnit.options);
    const result: { result?: string; error?: { errorCode: number; errorMessage: string } } = JSON.parse(resultData);
    if (result.error != null) {
      augmentErrorInformation(result.error);
      postMessage([
        "parse-error",
        {
          requestId: parseWorkUnit.requestId,
          error: result.error,
        },
      ]);
    } else {
      // istanbul ignore else
      if (result.result != null) {
        postMessage([
          "parse-result",
          {
            requestId: parseWorkUnit.requestId,
            result: result.result,
          },
        ]);
      }
    }
  }

  function workOnParseQueue(): void {
    if (!wasmReady || parseQueue.length === 0) {
      return;
    }

    // Initialization for first submitted work unit
    if (!contextAvailable) {
      createContext();
      if (!contextAvailable) {
        return;
      }
    }

    while (parseQueue.length !== 0) {
      processParseWorkUnit(<ParseWorkUnit>parseQueue.shift());
    }
  }

  function addScanWorkUnit(scanWorkUnit: ScanWorkUnit): void {
    workSubmitted = true;
    scanQueue.push(scanWorkUnit);
    workOnScanQueue();
  }

  function addParseWorkUnit(parseWorkUnit: ParseWorkUnit): void {
    workSubmitted = true;
    parseQueue.push(parseWorkUnit);
    workOnParseQueue();
  }

  function clearSession(): void {
    if (scannerSettingsReady) {
      Module._scanner_session_clear();
    }
  }

  function createBlurryTable(symbology: Barcode.Symbology): void {
    function reportResult(): void {
      postMessage(["create-blurry-table-result", symbology]);
    }

    if (!wasmReady || !contextAvailable) {
      return;
    }

    const symbologyLength: number = lengthBytesUTF8(symbology) + 1;
    const symbologyPointer: number = Module._malloc(symbologyLength);
    stringToUTF8(symbology, symbologyPointer, symbologyLength);
    Module._create_blurry_table(symbologyPointer);
    Module._free(symbologyPointer);

    // FS.syncfs is called by the engine if needed: the current promise is the one to wait for
    fsSyncPromise.then(reportResult).catch(
      // istanbul ignore next
      reportResult
    );
  }

  function setCameraProperties(cameraType: Camera.Type, autofocus: boolean): void {
    cameraProperties = {
      cameraType,
      autofocus,
    };
    if (!wasmReady || !contextAvailable) {
      return;
    }
    reportCameraProperties();
  }

  function reset(): void {
    clearSession();
    scanQueue.length = 0;
    parseQueue.length = 0;
    scanSettings = undefined;
    imageSettings = undefined;
    workSubmitted = false;
    scannerSettingsReady = false;
    scannerImageSettingsReady = false;
  }

  // Private

  function retryWithExponentialBackoff<T>(
    handler: () => Promise<T>,
    backoffMs: number,
    maxBackoffMs: number,
    singleTryRejectionCallback: (error: Error) => void
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      handler()
        .then(resolve)
        .catch((error) => {
          const newBackoffMs: number = backoffMs * 2;
          if (newBackoffMs > maxBackoffMs) {
            return reject(error);
          }
          singleTryRejectionCallback(error);
          setTimeout(() => {
            retryWithExponentialBackoff(handler, newBackoffMs, maxBackoffMs, singleTryRejectionCallback)
              .then(resolve)
              .catch(reject);
          }, backoffMs);
        });
    });
  }

  function getLibraryLocationURIs(libraryLocation: string): { jsURI: string; wasmURI: string } {
    let cdnURI: boolean = false;

    if (/^https?:\/\/([^\/.]*\.)*cdn.jsdelivr.net\//.test(libraryLocation)) {
      libraryLocation = "https://cdn.jsdelivr.net/npm/scandit-sdk@%VERSION%/build/";
      cdnURI = true;
    } else if (/^https?:\/\/([^\/.]*\.)*unpkg.com\//.test(libraryLocation)) {
      libraryLocation = "https://unpkg.com/scandit-sdk@%VERSION%/build/";
      cdnURI = true;
    }

    if (cdnURI) {
      return {
        jsURI: `${libraryLocation}scandit-engine-sdk.min.js`,
        wasmURI: `${libraryLocation}scandit-engine-sdk.wasm`,
      };
    }

    return {
      jsURI: `${libraryLocation}scandit-engine-sdk.min.js?v=%VERSION%`,
      wasmURI: `${libraryLocation}scandit-engine-sdk.wasm?v=%VERSION%`,
    };
  }

  function arrayBufferToHexString(arrayBuffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(arrayBuffer))
      .map((byteNumber) => {
        const byteHex: string = byteNumber.toString(16);

        return byteHex.length === 1 ? /* istanbul ignore next */ `0${byteHex}` : byteHex;
      })
      .join("");
  }

  function applyScanSettings(highQualitySingleFrameMode: boolean = false): void {
    if (!wasmReady || !contextAvailable || !workSubmitted || scanSettings == null) {
      return;
    }

    scannerSettingsReady = false;

    const parsedSettings: {
      matrixScanEnabled: boolean;
      gpuAcceleration: boolean;
      blurryRecognition: boolean;
    } = JSON.parse(scanSettings);
    const scanSettingsLength: number = lengthBytesUTF8(scanSettings) + 1;
    const scanSettingsPointer: number = Module._malloc(scanSettingsLength);
    stringToUTF8(scanSettings, scanSettingsPointer, scanSettingsLength);
    const resultPointer: number = Module._scanner_settings_new_from_json(
      scanSettingsPointer,
      parsedSettings.blurryRecognition && blurryRecognitionAvailable,
      parsedSettings.matrixScanEnabled,
      highQualitySingleFrameMode,
      gpuAccelerationAvailable && parsedSettings.gpuAcceleration
    );
    Module._free(scanSettingsPointer);

    const result: string = UTF8ToString(resultPointer);
    if (result !== "") {
      scannerSettingsReady = true;
      console.debug("External Scandit Engine scan settings:", JSON.parse(result));
    }
  }

  function applyImageSettings(): void {
    if (!wasmReady || !contextAvailable || !workSubmitted || imageSettings == null) {
      return;
    }

    scannerImageSettingsReady = false;

    let channels: number;
    // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
    if (imageSettings.format.valueOf() === 1) {
      // RGB_8U
      channels = 3;
    } else if (imageSettings.format.valueOf() === 2) {
      // RGBA_8U
      channels = 4;
    } else {
      // GRAY_8U
      channels = 1;
    }
    Module._scanner_image_settings_new(imageSettings.width, imageSettings.height, channels);
    if (imageBufferPointer != null) {
      Module._free(imageBufferPointer);
    }
    imageBufferSize = imageSettings.width * imageSettings.height * channels;
    imageBufferPointer = Module._malloc(imageBufferSize);

    scannerImageSettingsReady = true;
  }

  function reportCameraProperties(): void {
    if (!wasmReady || !contextAvailable || cameraProperties == null) {
      return;
    }
    // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
    Module._report_camera_properties(cameraProperties.cameraType === "front", cameraProperties.autofocus);
  }

  function scanImage(imageData: Uint8Array): string {
    if (imageData.byteLength !== imageBufferSize) {
      // This could happen in unexpected situations and should be temporary
      return JSON.stringify({ scanResult: [] });
    }

    Module.HEAPU8.set(imageData, imageBufferPointer);

    return UTF8ToString(Module._scanner_scan(<number>imageBufferPointer));
  }

  function parse(dataFormat: Parser.DataFormat, data: string | Uint8Array, options: string): string {
    const dataLength: number = typeof data === "string" ? lengthBytesUTF8(data) + 1 : data.byteLength;
    const dataPointer: number = Module._malloc(dataLength);
    if (typeof data === "string") {
      stringToUTF8(data, dataPointer, dataLength);
    } else {
      Module.HEAPU8.set(data, dataPointer);
    }
    const optionsLength: number = lengthBytesUTF8(options) + 1;
    const optionsPointer: number = Module._malloc(optionsLength);
    stringToUTF8(options, optionsPointer, optionsLength);
    const resultPointer: number = Module._parser_parse_string(
      dataFormat.valueOf(),
      dataPointer,
      dataLength - 1,
      optionsPointer
    );
    Module._free(dataPointer);
    Module._free(optionsPointer);

    return UTF8ToString(resultPointer);
  }

  function verifiedWasmFetch(wasmURI: string, awaitFullResponse: boolean): Promise<Response> {
    function verifyResponseData(responseData: ArrayBuffer): void {
      // istanbul ignore else
      if (typeof crypto?.subtle?.digest === "function") {
        crypto.subtle
          .digest("SHA-256", responseData)
          .then((hash) => {
            const hashString: string = arrayBufferToHexString(hash);
            // istanbul ignore if
            if (hashString !== "%ENGINE_WASM_HASH%") {
              console.error(
                `The Scandit SDK Engine library WASM file found at ${wasmURI} seems invalid: ` +
                  `expected file hash doesn't match (received: ${hashString}, ` +
                  `expected: ${"%ENGINE_WASM_HASH%"}). ` +
                  `Please ensure the correct Scandit SDK Engine file (with correct version) is retrieved.`
              );
            }
          })
          .catch(
            /* istanbul ignore next */ () => {
              // Ignored
            }
          );
      } else {
        console.warn(
          "Insecure context (see https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts): " +
            `The hash of the Scandit SDK Engine library WASM file found at ${wasmURI} could not be verified`
        );
      }
    }

    function tryFetch(): Promise<Response> {
      return new Promise((resolve, reject) => {
        fetch(wasmURI)
          .then((response) => {
            // istanbul ignore else
            if (response.ok) {
              response
                .clone()
                .arrayBuffer()
                .then((responseData) => {
                  if (awaitFullResponse) {
                    resolve(response);
                  }
                  verifyResponseData(responseData);
                })
                .catch(
                  // istanbul ignore next
                  (error) => {
                    if (awaitFullResponse) {
                      reject(error);
                    }
                  }
                );

              if (!awaitFullResponse) {
                resolve(response);
              }
            } else {
              reject(new Error("HTTP status code is not ok"));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    return retryWithExponentialBackoff(tryFetch, 250, 4000, (error) => {
      console.warn(error);
      console.warn(`Couldn't retrieve Scandit SDK Engine library at ${wasmURI}, retrying...`);
    }).catch((error) => {
      console.error(error);
      console.error(
        `Couldn't retrieve/instantiate Scandit SDK Engine library at ${wasmURI}, ` +
          "did you configure the path for it correctly?"
      );

      return Promise.reject(error);
    });
  }

  function instantiateWebAssembly(
    importObject: object,
    wasmURI: string,
    successCallback: (instance: WebAssembly.Instance) => void
  ): void {
    verifiedWasmFetch(wasmURI, true)
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((bytes) => {
        return self.WebAssembly.instantiate(bytes, importObject)
          .then((results: WebAssembly.WebAssemblyInstantiatedSource) => {
            successCallback(results.instance);
          })
          .catch((error: Error) => {
            console.error(error);
            console.error(
              `Couldn't instantiate Scandit SDK Engine library at ${wasmURI}, ` +
                "did you configure the path for it correctly?"
            );
          });
      })
      .catch(
        /* istanbul ignore next */ () => {
          // Ignored
        }
      );
  }

  function instantiateWebAssemblyStreaming(
    importObject: object,
    wasmURI: string,
    successCallback: (instance: WebAssembly.Instance) => void
  ): void {
    verifiedWasmFetch(wasmURI, false)
      .then((response) => {
        self.WebAssembly.instantiateStreaming(response, importObject)
          .then((results: WebAssembly.WebAssemblyInstantiatedSource) => {
            successCallback(results.instance);
          })
          .catch((error: Error) => {
            console.warn(error);
            console.warn(
              "WebAssembly streaming compile failed. " +
                "Falling back to ArrayBuffer instantiation (this will make things slower)"
            );
            instantiateWebAssembly(importObject, wasmURI, successCallback);
          });
      })
      .catch(
        /* istanbul ignore next */ () => {
          // Ignored
        }
      );
  }

  function syncFSMergePreloadedData(): Promise<number> {
    const fsObjectStoreName: string = "FILE_DATA";
    let resolveCallback: (value: number | PromiseLike<number>) => void;
    let openDbSourceRequest: IDBOpenDBRequest;
    let openDbTargetRequest: IDBOpenDBRequest;

    function handleError(this: IDBOpenDBRequest | IDBTransaction | IDBRequest | { error: Error }): void {
      openDbSourceRequest?.result?.close();
      openDbTargetRequest?.result?.close();
      // this.error
      resolveCallback(0);
    }

    function performMerge(): void {
      try {
        const objects: { value: object; primaryKey: IDBValidKey }[] = [];
        const sourceTransaction: IDBTransaction = openDbSourceRequest.result.transaction(fsObjectStoreName, "readonly");
        sourceTransaction.onerror = handleError;
        const cursorRequest: IDBRequest<IDBCursorWithValue | null> = sourceTransaction
          .objectStore(fsObjectStoreName)
          .openCursor();
        cursorRequest.onsuccess = () => {
          const cursor: IDBCursorWithValue | null = cursorRequest.result;
          if (cursor == null) {
            try {
              let mergedObjectsCount: number = 0;
              const targetTransaction: IDBTransaction = openDbTargetRequest.result.transaction(
                fsObjectStoreName,
                "readwrite"
              );
              const targetObjectStore: IDBObjectStore = targetTransaction.objectStore(fsObjectStoreName);
              targetTransaction.onerror = handleError;
              targetTransaction.oncomplete = () => {
                openDbSourceRequest.result.close();
                openDbTargetRequest.result.close();

                return resolveCallback(mergedObjectsCount);
              };
              for (const object of objects) {
                const countRequest: IDBRequest<number> = targetObjectStore.count(object.primaryKey);
                countRequest.onsuccess = () => {
                  if (countRequest.result === 0) {
                    ++mergedObjectsCount;
                    targetObjectStore.add(object.value, object.primaryKey);
                  }
                };
              }
            } catch (error) {
              // istanbul ignore next
              handleError.call({ error });
            }
          } else {
            objects.push({
              value: cursor.value,
              primaryKey: cursor.primaryKey
                .toString()
                .replace(`${writableDataPathPreload}/`, `${writableDataPathStandard}/`),
            });
            cursor.continue();
          }
        };
        cursorRequest.onerror = handleError;
      } catch (error) {
        // istanbul ignore next
        handleError.call({ error });
      }
    }

    return new Promise((resolve) => {
      resolveCallback = resolve;
      openDbSourceRequest = indexedDB.open(writableDataPathPreload);
      openDbSourceRequest.onupgradeneeded = () => {
        try {
          openDbSourceRequest.result.createObjectStore(fsObjectStoreName);
        } catch (error) {
          // Ignored
        }
      };
      openDbSourceRequest.onsuccess = () => {
        if (!Array.from(openDbSourceRequest.result.objectStoreNames).includes(fsObjectStoreName)) {
          return resolve(0);
        }

        openDbTargetRequest = indexedDB.open(writableDataPathStandard);
        openDbTargetRequest.onupgradeneeded = () => {
          try {
            openDbTargetRequest.result.createObjectStore(fsObjectStoreName);
          } catch (error) {
            // Ignored
          }
        };
        openDbTargetRequest.onsuccess = () => {
          performMerge();
        };
        openDbTargetRequest.onblocked = openDbTargetRequest.onerror = handleError;
      };
      openDbSourceRequest.onblocked = openDbSourceRequest.onerror = handleError;
    });
  }

  function syncFSPromisified(populate: boolean, initialPopulation: boolean): Promise<void> {
    // istanbul ignore if
    if (originalFSSyncfs == null) {
      return Promise.resolve();
    }
    fsSyncInProgress = true;

    return new Promise((resolve, reject) => {
      // Merge with data coming from preloading workers if needed
      (!preloading && populate ? syncFSMergePreloadedData() : Promise.resolve(0))
        .then((mergedObjects) => {
          if (!preloading && populate && !initialPopulation && mergedObjects === 0) {
            fsSyncInProgress = false;

            return resolve();
          }
          // tslint:disable-next-line: no-non-null-assertion
          originalFSSyncfs!(populate, (error) => {
            fsSyncInProgress = false;
            // istanbul ignore if
            if (error != null) {
              return reject(error);
            }
            resolve();
          });
        })
        .catch(reject);
    });
  }

  function syncFS(
    populate: boolean,
    initialPopulation: boolean = false,
    forceScheduling: boolean = false
  ): Promise<void> {
    if (!fsSyncScheduled || forceScheduling) {
      if (fsSyncInProgress) {
        fsSyncScheduled = true;
        fsSyncPromise = fsSyncPromise.then(() => {
          fsSyncScheduled = false;

          return syncFSPromisified(populate, initialPopulation);
        });
      } else {
        fsSyncPromise = syncFSPromisified(populate, initialPopulation);
      }
    }

    return fsSyncPromise;
  }

  function setupFS(): Promise<void> {
    // FS.syncfs is also called by the engine on file storage, ensure everything is coordinated nicely
    originalFSSyncfs = FS.syncfs;
    FS.syncfs = <typeof FS.syncfs>((populate: boolean, callback: (e: Error | void) => void) => {
      const originalCallback: typeof callback = callback;
      callback = (error) => {
        originalCallback(error);
      };
      syncFS(populate).then(callback).catch(callback);
    });

    try {
      FS.mkdir(writableDataPath);
    } catch (error) {
      // istanbul ignore next
      if (error.code !== "EEXIST") {
        originalFSSyncfs = undefined;

        return Promise.reject(error);
      }
    }
    FS.mount(IDBFS, {}, writableDataPath);

    return syncFS(true, true);
  }

  return {
    loadLibrary,
    setScanSettings,
    setImageSettings,
    workOnScanQueue,
    workOnParseQueue,
    addScanWorkUnit,
    addParseWorkUnit,
    clearSession,
    createBlurryTable,
    setCameraProperties,
    reset,
  };
}

// istanbul ignore next
function engineWorkerFunction(): void {
  const engineInstance: Engine = engine();

  onmessage = (e) => {
    // Creating context triggers license key verification and activation: delay until first frame processed
    const data: EngineReceivedMessageData = e.data;
    switch (data.type) {
      case "load-library":
        // tslint:disable-next-line: no-floating-promises
        engineInstance.loadLibrary(
          data.deviceId,
          data.libraryLocation,
          data.path,
          data.preload,
          data.delayedRegistration,
          data.licenseKey,
          data.deviceModelName
        );
        break;
      case "scan-settings":
        engineInstance.setScanSettings(
          data.settings,
          data.blurryRecognitionAvailable,
          data.blurryRecognitionRequiresUpdate
        );
        break;
      case "image-settings":
        engineInstance.setImageSettings(data.imageSettings);
        break;
      case "scan-image":
        engineInstance.addScanWorkUnit({
          requestId: data.requestId,
          data: data.data,
          highQualitySingleFrameMode: data.highQualitySingleFrameMode,
        });
        break;
      case "parse":
        engineInstance.addParseWorkUnit({
          requestId: data.requestId,
          dataFormat: data.dataFormat,
          data: data.data,
          options: data.options,
        });
        break;
      case "clear-session":
        engineInstance.clearSession();
        break;
      case "create-blurry-table":
        engineInstance.createBlurryTable(data.symbology);
        break;
      case "camera-properties":
        engineInstance.setCameraProperties(data.cameraType, data.autofocus);
        break;
      case "reset":
        engineInstance.reset();
        break;
      default:
        break;
    }
  };
}

/**
 * @hidden
 */
export const engineWorkerBlob: Blob = new Blob(
  [`var Module;${engine.toString()}(${engineWorkerFunction.toString()})()`],
  {
    type: "text/javascript",
  }
);
