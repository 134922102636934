import { AttributeDescriptor } from "./schema";
import { assertUnreachable } from "./tsHelper";

export type ConvertedAttribute = boolean | string | unknown[] | number | object | undefined;

export function convertAttribute(schema: AttributeDescriptor, value: string | null): ConvertedAttribute | undefined {
  switch (schema.type) {
    case "boolean":
      return convertToBoolean(value, schema.default);
    case "string":
    case "guiStyle":
    case "cameraType":
    case "videoFit":
    case "codeDirection":
      return convertToString(value, schema.default);
    case "array":
      return convertToArray(value, schema.default);
    case "integer":
      return convertToInteger(value, schema.default);
    case "singleImageModeSettings":
    case "searchArea":
    case "camera":
    case "cameraSettings":
      return convertJsonToObject(value, schema.default);
    default:
      break;
  }

  // Trick to make sure all cases are covered:
  return assertUnreachable(schema);
}

function convertToString(input: string | null, defaultValue: string): string {
  return input == null ? defaultValue : input;
}

function convertToBoolean(input: string | null, defaultValue: boolean): boolean {
  if (input == null) {
    return defaultValue;
  }

  return input !== "false";
}

function convertToInteger(input: string | null, defaultValue: number): number {
  if (input == null) {
    return defaultValue;
  }
  const parsed: number = parseInt(input, 10);
  if (isNaN(parsed)) {
    return defaultValue;
  }

  return parsed;
}

function convertToArray(input: string | null, defaultValue: unknown[]): unknown[] {
  if (input == null) {
    return defaultValue;
  }

  const json: JSON | null = toJson(input);
  if (json == null || !Array.isArray(json)) {
    return defaultValue;
  }

  return json;
}

function convertJsonToObject(input: string | null, defaultValue: object | undefined): object | undefined {
  if (input == null) {
    return defaultValue;
  }
  const json: JSON | null = toJson(input);
  if (json == null) {
    return defaultValue;
  }
  // must be an object
  if (Array.isArray(json) || typeof json === "number") {
    return defaultValue;
  }

  return json;
}

function toJson(input: string): JSON | null {
  try {
    return JSON.parse(input);
  } catch (e) {
    return null;
  }
}
