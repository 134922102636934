import { BarcodePicker } from "..";
import { default as pickerStyles } from "../styles/styles.scss";
import { AttributeValue, BarcodePickerView } from "./barcodePickerView";
import { Controller } from "./controller";
import { attributes } from "./schema";

export class ScanditBarcodePicker extends HTMLElement implements BarcodePickerView {
  public static readonly TAG_NAME: string = "scandit-barcode-picker";

  private readonly shadowDom: ShadowRoot;

  private readonly controller: Controller;

  static get observedAttributes(): string[] {
    return attributes.map((s: string) => {
      return s.toLowerCase();
    });
  }

  constructor() {
    super();
    this.controller = new Controller(this);
    this.shadowDom = this.attachShadow({ mode: "open" });
  }

  public static registerComponent(): undefined | string {
    if (!("customElements" in window)) {
      return;
    }
    if (!customElements.get(ScanditBarcodePicker.TAG_NAME)) {
      customElements.define(ScanditBarcodePicker.TAG_NAME, ScanditBarcodePicker);
    }

    return ScanditBarcodePicker.TAG_NAME;
  }

  /**
   * Expose main objects on view
   */
  get barcodePicker(): BarcodePicker | undefined {
    return this.controller.picker;
  }

  public get root(): HTMLElement {
    return <HTMLDivElement>this.shadowDom.querySelector<HTMLDivElement>("#root");
  }

  public async connectedCallback(): Promise<void> {
    await this.controller.viewConnectedCallback();
  }

  public disconnectedCallback(): void {
    this.controller.viewDisconnectedCallback();
  }

  public attributeChangedCallback(name: string): void {
    this.controller.attributeChangedCallback(name);
  }

  public initializeDom(): void {
    // tslint:disable-next-line:no-inner-html
    this.shadowDom.innerHTML = this.initialDomContent;
  }

  public dispatchCustomEvent(e: CustomEvent): void {
    this.dispatchEvent(e);
  }

  public getAttributes(): AttributeValue[] {
    return Array.from(this.attributes).map((att) => {
      return { name: att.name, value: att.value };
    });
  }

  public waitOnChildrenReady(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, 50);
    });
  }

  private get initialDomContent(): string {
    return `
      <style>${this.styles}</style>
      <div id="root"></div>
    `;
  }

  private get wcStyles(): string {
    return `
      :host {
        display: block;
      }

      :host([hidden]) {
        display: none;
      }

      #root {
        height: inherit;
        max-height: inherit;
      }
    `;
  }

  private get styles(): string {
    return `
      ${this.wcStyles}
      ${pickerStyles}
    `;
  }
}
